/* .btn{
    border-radius:1px solid black;
    cursor:pointer;
    background-color: var(--color);
}
.btn-1{
    --color:red;
}
.btn-2{
    --color:green;
}
.btn-3{
    --color:cyan;
}
.btn-4{
    --color:chocolate;
} */

.accordion-button::after{
    filter: invert(1);
}